<div class="container" id="mainContainer">
  <div style="display: flex; flex-direction: column; justify-content: center; width: 100%;">
    <header id="header" *ngIf="headerLogo">
      <img [src]="headerLogo | safeUrl" id="headerImg">
    </header>
    <main style="display: flex; flex-direction: column; justify-content: space-evenly;">
      <router-outlet></router-outlet>
    </main>
    <footer id="footer">
      <img [src]="footerLogo | safeUrl" id="footerImg">
    </footer>
  </div>
</div>
