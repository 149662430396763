<mat-progress-bar mode="indeterminate" *ngIf="saving"></mat-progress-bar>
<block-ui>
  <div class="container">
    <div class="formContainer"
    [ngClass]="dataService.idPollType == idTestVocacionalV2 && 'v2Style'">
      <mat-card [ngClass]="dataService.idPollType == idTestVocacionalV2 && 'transparentCard'" [style]="'background: ' + backgroundCardColor">
        <mat-card-content>
          <mat-horizontal-stepper linear labelPosition="bottom" #stepper style="background: none">
            <mat-step *ngFor="let focus of pollStructure.focuses; let i = index">
              <div class="row" [ngClass]="dataService.idPollType == idTestVocacionalV2 && 'v2Text'" *ngIf="focus.title && focus.title.trim() != ''">
                <h1>
                  <div [innerHTML]="focus.title | safeHtml"></div>
                </h1>
              </div>
              <div class="row" *ngFor="let question of focus.questions">
                <mat-card style="margin-top: 10px" *ngIf="question.question && question.question.trim() != '' ? (question.hasCondition ? showQuestion(question) : true) : false">
                  <!-- [style]="{'background-color': dataService.idPollType == idTestVocacionalV2 ? question.value ? '#DBFFDB' : question.valueText ? '#DBFFDB' : '#FFFFFF' : '#FFFFFF'}"> -->
                  <mat-card-content>
                    <h3 class="question" [ngClass]="dataService.idPollType == idTestVocacionalV2 && 'v2Text'">
                      <div [innerHTML]="question.question | safeHtml"></div>
                    </h3>
                    <div class="optionsContainer"
                      *ngIf="question.idQuestionType == rangeValueOption && dataService.idPollType == idTestVocacionalV2">
                      <button mat-icon-button class="optionButton" [id]="'questionOption_' + question.id + '_' + i"
                        *ngFor="let value of values; let i = index" (click)="setValue(question, i + 1)"></button>
                    </div>
                    <mat-radio-group class="optionsContainer" [(ngModel)]="question.value"
                      *ngIf="question.idQuestionType == rangeValueOption && dataService.idPollType != idTestVocacionalV2">
                      <mat-radio-button class="example-radio-button" *ngFor="let value of values" [value]="value">
                        {{value}}
                      </mat-radio-button>
                    </mat-radio-group>
                    <!-- Muestra la opcion para responder opciones personalizadas -->
                    <mat-radio-group class="optionsContainer" [(ngModel)]="question.valueText"
                      *ngIf="question.idQuestionType == customRangeOption">
                      <div *ngFor="let option of question.questionListOptions">
                        <mat-radio-button *ngIf="!option.image" [value]="option.key">
                          {{option.value}}
                        </mat-radio-button>
                        <mat-radio-button *ngIf="option.image" class="img-radio" [value]="option.key">
                          <img [src]="option.image | safeUrl" [alt]="option.value">
                        </mat-radio-button>
                      </div>
                    </mat-radio-group>
                    <!-- Muestra la opcion para responder SI o NO -->
                    <mat-radio-group class="optionsContainer optionsSINO" [(ngModel)]="question.value"
                      *ngIf="question.idQuestionType == yesNoOptions">
                      <mat-radio-button class="example-radio-button" [value]="10">
                        SI
                      </mat-radio-button>
                      <mat-radio-button class="example-radio-button" [value]="1">
                        NO
                      </mat-radio-button>
                    </mat-radio-group>
                    <!-- Muestra la opcion para responder con texto Libre -->
                    <mat-form-field class="form-full-width optionText" *ngIf="question.idQuestionType == textOptions">
                      <mat-label>Respuesta</mat-label>
                      <textarea matInput #message [(ngModel)]="question.valueText"></textarea>
                      <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
                    </mat-form-field>
                    <!-- Muestra la opcion para responder eligiendo de una lista de opciones -->
                    <mat-form-field class="form-full-width optionList" *ngIf="question.idQuestionType == listOptions">
                      <mat-label>Seleccione una opcion</mat-label>
                      <mat-select [(ngModel)]="question.valueText">
                        <ng-container *ngFor="let opcion of question.questionListOptions">
                          <mat-option [value]="opcion.key" *ngIf="opcion.active">
                            {{ opcion.value }}
                          </mat-option>
                        </ng-container>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="form-full-width optionList" *ngIf="question.idQuestionType == autocomplete">
                      <mat-label>Escriba su respuesta</mat-label>
                      <input matInput [matAutocomplete]="auto" [formControl]="autocompleteInput">
                      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                        (optionSelected)="question.value = $event.option.value.idPossibleAnswers; question.valueText = $event.option.value.value">
                        <mat-option *ngIf="isLoading" class="is-loading">
                          <mat-spinner diameter="50"></mat-spinner>
                        </mat-option>
                        <ng-container *ngIf="!isLoading">
                          <mat-option *ngFor="let answers of filteredAnswers" [value]="answers">
                            <span>{{ answers.value }}</span>
                          </mat-option>
                        </ng-container>
                      </mat-autocomplete>
                    </mat-form-field>
                    <div class="row optionCheckbox" *ngIf="question.idQuestionType == checkboxOptions">
                      <ul>
                        <li *ngFor="let option of question.questionListOptions">
                          <mat-checkbox [(ngModel)]="option.selected">
                            {{ option.value }}
                          </mat-checkbox>
                        </li>
                      </ul>
                    </div>
                    <!-- </div> -->
                  </mat-card-content>
                </mat-card>
              </div>
              <div class="row">
                <mat-card-actions align=end style="text-align: center;">
                  <button mat-raised-button color="secondary" *ngIf="i > 0"
                    (click)="previousStep(stepper, i)">Atrás</button>
                  <button mat-raised-button color="primary" (click)="nextStep(stepper, i)"
                    *ngIf="i != (pollStructure.focuses.length - 1)"
                    [disabled]="!canGo(focus.questions)">Siguiente</button>
                  <button mat-raised-button color="primary" (click)="finishPoll()"
                    *ngIf="i == (pollStructure.focuses.length - 1)"
                    [disabled]="!canGo(focus.questions)">{{dataService.idPollType == idTestVocacionalV2 ? 'Siguiente' : 'Finalizar'}}</button>
                </mat-card-actions>
              </div>
            </mat-step>
            <ng-template matStepperIcon="number" let-index="index">
              <div [id]="'matStepperIcon_' + index" style="filter: grayscale(1);">
                <img [src]="'./../../assets/img/icons/Icon_' + index + '.png'" class="customStepIcon" />
              </div>
            </ng-template>
            <ng-template matStepperIcon="selected" let-index="index">
              <div [id]="'matStepperIcon_' + index">
                <img [src]="'./../../assets/img/icons/Icon_' + index + '.png'" class="customStepIcon" />
              </div>
            </ng-template>
            <ng-template matStepperIcon="edit" let-index="index">
              <div [id]="'matStepperIcon_' + index">
                <img [src]="'./../../assets/img/icons/Icon_' + index + '.png'" class="customStepIcon" />
              </div>
            </ng-template>
            <ng-template matStepperIcon="done" let-index="index">
              <div [id]="'matStepperIcon_' + index">
                <img [src]="'./../../assets/img/icons/Icon_' + index + '.png'" class="customStepIcon"/>
              </div>
            </ng-template>
          </mat-horizontal-stepper>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</block-ui>
