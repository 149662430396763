import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/services/data.service';
import { base64StringToBlob } from 'blob-util';
import { PollsService } from 'src/services/polls.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {
  message: any;
  instanceId: any;
  resultado: any;
  idTestVocacionalV2: number;

  constructor(private router: Router,
    private service: PollsService,
    public dataService: DataService) {
      this.idTestVocacionalV2 = parseInt(this.service.getIdTestVocacionalV2());
    }

  ngOnInit(): void {
    const hasExtraUserData = localStorage.getItem('hasExtraUserData').toLowerCase() == "true";
    if (hasExtraUserData) {
      const idPollType = parseInt(localStorage.getItem("idPollType"));
      const campaign = parseInt(localStorage.getItem("idCampaign"));
      this.router.navigate(['/poll/' + idPollType + "/" + campaign]);
    }
    const finishedPoll = this.dataService.finishedPoll;
    this.message = finishedPoll.mensaje
    this.instanceId = finishedPoll.instanceId;
    this.resultado = finishedPoll.resultado;
    if (!this.resultado) {
      setTimeout(() => {
        try {
          window.top.postMessage('finishReminder','*');
          window.top.postMessage('closeReminder','*');
        } catch (error) {}
      }, 5000);
    }
  }

  openDoc() {
    let blob = base64StringToBlob(this.resultado, 'application/pdf');
    this.downloadDoc(blob, "Resultados Test.pdf")
    // window.open(result.resultado, "_self");
    // window.open(this.resultado.substring(this.resultado.indexOf('">') + 2, this.resultado.lastIndexOf('<')));
  }

  private openBlobDoc(blob) {
    var url = window.URL.createObjectURL(blob);
    window.open(url, "_self");
  }

  private downloadDoc(blob, name) {
    const doc = document.createElement('a');
    doc.href = URL.createObjectURL(blob);
    doc.download = name;
    document.body.appendChild(doc);
    doc.click();
    document.body.removeChild(doc);
    this.openBlobDoc(blob);
  }

}
