import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BlockUIModule } from 'ng-block-ui';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { SafeHtmlPipe } from 'src/pipes/safeHtml.pipe';
import { DataService } from 'src/services/data.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PersonalInformationComponent } from './personal-information/personal-information.component';
import { PollComponent } from './poll/poll.component';
import { QuestionsComponent } from './questions/questions.component';
import { StartComponent } from './start/start.component';
import { SummaryComponent } from './summary/summary.component';
import { UserPollsComponent } from './user-polls/user-polls.component';
import { SafeUrlPipe } from 'src/pipes/safeUrl.pipe';

@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    PersonalInformationComponent,
    QuestionsComponent,
    SummaryComponent,
    UserPollsComponent,
    PollComponent,
    SafeHtmlPipe,
    SafeUrlPipe
  ],
  imports: [
    BrowserModule,
    BlockUIModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatInputModule,
    MatCardModule,
    MatStepperModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatTooltipModule
  ],
  providers: [MatDatepickerModule, DataService, SafeHtmlPipe, SafeUrlPipe, { provide: MAT_DATE_LOCALE, useValue: 'es-AR' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
